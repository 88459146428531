input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.additional-condition-bar .p-scrollpanel-bar {
  background-color: #a8a8a8 !important;
  opacity: 1 !important;
}

.additionalItems >div{
  z-index: 500 !important;
}

.autocomplete {
  border: 1px solid #ccc;
  border-radius: 4px; 
  padding: 8px; 
}